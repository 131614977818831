<template>
  <router-view></router-view>
</template>

<script>
  import swal from 'sweetalert2';
  import Usuarios from '@/api-base/usuarios.service';
  import Constantes from '@/api-services/Constantes'

  import Notificaciones from '@/api-base/notificaciones.service';
  // Notice how this gets configured before we load Font Awesome
  window.FontAwesomeConfig = { autoReplaceSvg: false }
  

  export default {
    data() {
      return {
        SignalRIniciado: false,
      }
    },
    methods: {
      async accept() {
        this.showUpdateUI = false;
        await this.$workbox.messageSW({ type: "SKIP_WAITING" });
      },

      initializeLayout() {
        /* if (!this.$rtl.isRTL) {
           // Just make sure rtl css is off when we are not on rtl
           this.$rtl.disableRTL();
         }*/
      }
    },
    created() {
      Notificaciones.IniciaTareas();
      console.debug(`SignalRIni= ${Notificaciones.SignalRIni}`);
      if (!Notificaciones.SignalRIni && Constantes.UsarSignalR) {
        Notificaciones.SignalRIni = true;

        this.$socket.start({
          //  log: true // Logging is optional but very helpful during development
          log: false
        }).then(function () {
          console.log(`sockets.Conectado=`);
        })
          .catch(function (error) {
            //  console.log(error);
            /*if (error) {
              if (error.message) {
                console.error(error.message);
              }

              if (error.statusCode && error.statusCode === 401) {
                appendMessage('_BROADCAST_', 'You\'re not logged in. Click <a href="/login">here</a> to login with GitHub.');
              }
            }*/
          });

      }
      if (this.$workbox) {
        this.$workbox.addEventListener("waiting", () => {
          swal.fire({
            title: 'Nueva Versión',
            text: `Tenemos cosas nuevas para ti. ¿Deseas actualizar en este momento?`,
            icon: 'info',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn   ',
              cancelButton: 'btn btn-danger'
            },
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            buttonsStyling: false
          }).then((resultado) => {
            //  console.debug(resultado);
            if (resultado.isConfirmed)
              this.accept();
            //this.showUpdateUI = true;

          });
        });
      }
    },
    mounted() {
      this.initializeLayout();
    },
    sockets: {
      //El server lo enviará solicitando la sesión, si no se tiene no se enviará
      OnAuth(Datos) {
        this.$nextTick(() => {
          if (Usuarios.estaLogueado()) {
            var Sesion = Usuarios.getSesion();
            this.$socket.invoke('Auth', Sesion);
          }
        });
      },
      OnAuthCorrecto(Datos) {
        console.debug(Datos);
       // console.debug("Prueba");
        console.debug(Notificaciones.OnAuthCorrectoRnd);
       
      //  location.reload();
        if (Notificaciones.OnAuthCorrectoRnd == Datos.rnd) {
         
          try {
            console.debug("Recargando");
            location.reload();
            console.debug("Recargado");
            return;
          }
          catch {

          }
        }
        Notificaciones.OnAuthCorrectoRnd = Datos.rnd;
        console.debug(Notificaciones.OnAuthCorrectoRnd);
      },
      OnNotificacion(Datos) {
        this.$nextTick(() => {
          Notificaciones.Agrega(this, Datos);
        });
        //console.log(`sockets.OnConexion=${JSON.stringify(Datos)}`);
      },
      OnAcceso(Datos) {
        // this.$notify({ verticalAlign: 'top', horizontalAlign: 'right', message: 'Top right' });
        // From what i've read this has to be here or the events wont fire
        // console.log(`sockets.OnConexion=${JSON.stringify(Datos)}`);
      },
      OnConexion(Datos) {
        /* this.$notify({
           icon: 'fas fa-fingerprint', verticalAlign: 'top', horizontalAlign: 'right',
           message: `Nueva checada de: ${Datos.pin} ${Datos.etiqueta}`
         });*/
        // From what i've read this has to be here or the events wont fire
        // console.log(`sockets.OnConexion`);
      },
      broadcastMessage(name, message) {
        // From what i've read this has to be here or the events wont fire
        //console.log(`sockets.broadcastMessage=${name},${message}`);
      },
      ReceiveMessage(message) {
        // From what i've read this has to be here or the events wont fire
        // console.log(`sockets.ReceiveMessage=${message}`);
      }
    }
  };
</script>
