//import Axios from 'axios';
//import LiAuth from '@/api-base/liauth.service'
import Base from '@/api-base/base'
//import Suscripciones from '@/api-base/suscripciones.service'
//const SUSCRIPCIONESUSUARIOS = `${Base.URL}/suscripcionesusuarios`;

export default {
  //Es la suscripción usuario
  SuscripcionesUsuario: null,
  SuscripcionUsuario: null,
  PerfilID: 'Desconocido',
  //obtiene el SuscripcionID seleccionada
  getSuscripcionID() {
    return localStorage.suscripcionID;
  },
  //Asigna la suscripción seleccionada
  setSuscripcionID(SuscripcionID) {
    // console.debug(`value=${SuscripcionID}`);
    localStorage.suscripcionID = SuscripcionID;
  },
  setSuscripcionesUsuario(SuscripcionesUsuario) {
    this.SuscripcionesUsuario = SuscripcionesUsuario;
  },
  getSuscripcionesUsuario() {

    if (this.SuscripcionesUsuario == null) {
      try {
        var Ls = localStorage.perfilLocal;

        if (Ls != null && Ls != "") {
          var PerfilLocal = JSON.parse(Ls);
          //  console.debug(`PerfilLocal=${PerfilLocal}`);
          this.SuscripcionesUsuario = PerfilLocal.usuario.suscripcionesUsuario;
          return this.SuscripcionesUsuario;
        }
      }
      catch { }
      return [];
    }
    return this.SuscripcionesUsuario;
  },
  //Obtiene una suscripción en particular si no se pasa el suscripcion ID obtendrá la seleccionada
  getSuscripcionUsuario(SuscripcionID) {
    var SuscripcionesUsuario = this.getSuscripcionesUsuario();
    //  console.debug(SuscripcionesUsuario);
    if (SuscripcionID == null)
      SuscripcionID = this.getSuscripcionID();


    var SU = SuscripcionesUsuario.find(item => item.suscripcionID == SuscripcionID);
    this.SuscripcionUsuario = SU;
    if (SU != null)
      this.PerfilID = SU.perfilID;
    return SU;
  },
  getSuscripcion(SuscripcionID) {
    var SU = this.getSuscripcionUsuario(SuscripcionID);
    if (SU == null)
      return SU;
    return SU.suscripcion;
  },
  getPlan(SuscripcionID) {
    var Suscripcion = this.getSuscripcion(SuscripcionID);
    if (Suscripcion == null)
      return "";
    return Suscripcion.plan;
  },
  getEtiqueta(SuscripcionID) {
    var Suscripcion = this.getSuscripcion(SuscripcionID);
    if (Suscripcion == null)
      return "";
    return Suscripcion.etiqueta;
  },
  Plan: "",
  getPlan(SuscripcionID) {
    if (SuscripcionID == null && this.Plan != "") {
      return this.Plan;
    }
    var Suscripcion = this.getSuscripcion(SuscripcionID);
    if (Suscripcion == null)
      return false;
    if (SuscripcionID == null) {
      this.Plan = Suscripcion.plan;
    }
    return Suscripcion.plan;
  },
  esAdministrador(SuscripcionID) {
    var SU = this.getSuscripcionUsuario(SuscripcionID);
    if (SU == null)
      return SU;
    return SU.perfilID == 1;
  },
  permitirMultiplesAdministradores(SuscripcionID) {
    switch (this.getPlan(SuscripcionID)) {
      //      case "Gratuito":
      //      case "Bronce":
      //      case "Plata":
      case "Oro":
      case "Platino":
      case "Diamante":
      case "DiamanteElite":
        return true;
      default:
        return false;
        break;
    }
  },
  puedeCrearAdministradores(SuscripcionID) {
    var SU = this.getSuscripcionUsuario(SuscripcionID);
    if (SU == null)
      return false;
    if (SU.perfilID != this.ePerfiles[1])
      return false;

    return this.permitirMultiplesAdministradores(SuscripcionID);
  },
  puedeCrearClaves(SuscripcionID) {
    switch (this.getPlan(SuscripcionID)) {
      //      case "Gratuito":
      //      case "Bronce":
      case "Plata":
      case "Oro":
      case "Platino":
      case "Diamante":
      case "DiamanteElite":
        return true;
      default:
        return false;
        break;
    }
  },

  puedeAccesoTotal(SuscripcionID) {
    switch (this.getPlan(SuscripcionID)) {
      //      case "Bronce":
      //case "Plata":
      case "Oro":
      case "Platino":
      case "Diamante":
      case "DiamanteElite":
        return true;
      default:
        return false;
        break;
    }
  },
  puedeMultiplesDispositivos(SuscripcionID) {
    switch (this.getPlan(SuscripcionID)) {
      //      case "Gratuito":
      //      case "Bronce":
      //case "Plata":
      case "Oro":
      case "Platino":
      case "Diamante":
      case "DiamanteElite":
        return true;
      default:
        return false;
        break;
    }
  },

  //Obtene los perfiles "Son constantes" definidas en el server;
  ePerfiles: ["Desconocido", "Administrador", "Supervisor", "Colaborador"],

  ObtenEtiquetaPerfil(PerfilID) {
    switch (PerfilID) {
      case "Colaborador": return "Auto Servicio";
      case "Visor": return "Visor";
      case "Supervisor": return "Supervisor";
      case "Editor": return "Editor de Personal";
      case "Administrador": return "Administrador";
    }
    return "Desconocido";
  },

  /*
   * Obtiene la etiqueta de una suscripción, solo sobre las que tiene acceso el usuario actual
   * */
  ObtenEtiqueta(SuscripcionID) {

    return getEtiqueta(SuscripcionID);
  },
  ObtenSuscripciones(EventoOnSuscripciones) {
    if (this.Suscripciones.length < 1) {
      var Yo = this;
      setTimeout(function () { Yo.ObtenSuscripciones(EventoOnSuscripciones); }, 1000);
    }
    else
      EventoOnSuscripciones(this.Suscripciones);
  },
  ObtenUrlInvitacion(Reunion) {
    return window.location.protocol + "//" + window.location.host + "/#/ri?i=" + Reunion.reunionAlternoID;
  },
  ObtenUrlEdicion(Reunion) {
    return window.location.protocol + "//" + window.location.host + "/#/usuarios/edicion?i=" + Reunion.reunionAlternoID;
  },
  /*
  getUsuario(UsuarioID) {
    var R = Axios.get(SUSCRIPCIONESUSUARIOS + `/${UsuarioID}`, LiAuth.header());
    return R;
  },
  get() {
    var R = Axios.get(SUSCRIPCIONESUSUARIOS, LiAuth.header());

    return R;
  },
  postUsuario(data) {
    return Axios.post(SUSCRIPCIONESUSUARIOS, data, LiAuth.header());
  },
  putUsuario(reunionID, data) {
    return Axios.put(`${SUSCRIPCIONESUSUARIOS}/${reunionID}`, data, LiAuth.header());
  },*/


};
