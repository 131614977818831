<template>
  <div v-if="Carga" class="mb-2 card  p-2" style="min-width: 290px;" :class="CardClass" >

    <Main-Cargando :cargando="cargando" :MsgError="MsgError">
      <div v-if="Carga.Colaborador" >
        <router-link :to="{}" @click.native="OnMostrar" >
          <img :src="Carga.Colaborador | Foto" class="rounded-circle float-left mr-2 " width="48" style="height:48px">
          <h4 class="mb-0 pb-0 pt-1 text-truncate ">{{Carga.Colaborador.Etiqueta}} </h4>
          <h5 class="mb-0 pb-0 text-truncate "><i :class="Icono"></i>{{Etiqueta}} {{Carga.Colaborador.LinkID}} </h5>
          
        </router-link>
      </div>
      <div v-else>

        <router-link :to="{}" class="pull-right p-2 " data-effect="fadeOut" @click.native.stop="OnAutorizar()"><i class="fa fa-times"></i></router-link>
        <router-link class="" :to="{}" data-effect="fadeOut" @click.native="OnMostrar">
          <img v-if="Carga.Respuesta && Carga.Respuesta.Colaborador" :src="Carga.Respuesta.Colaborador | Foto" class="rounded-circle float-left mr-2 " width="48" style="height:48px">
          <h4 v-if="Carga.Respuesta && Carga.Respuesta.Colaborador" class="mb-0 pb-0 pt-1 text-truncate ">{{Carga.Respuesta.Colaborador.Etiqueta}} </h4>
          <h4 class="mb-0 pb-0 text-truncate "><i :class="Icono"></i> {{Etiqueta}}</h4>
          <h5 class="mb-0 pb-0 text-truncate ">{{Datos}}</h5>
        </router-link>



      </div>
    </Main-Cargando>




    <NotificacionModal v-if="Modal" :Mostrar.sync="Modal" :MsgError="MsgError"
                       v-model="LaNotificacion" @autorizar="OnAutorizar" :cargando="cargando"></NotificacionModal>
  </div>
</template>

<script>
  import moment from 'moment'
  //var moment = require('moment');
  moment.locale('es-mx');
  import NotificacionModal from './Notificacion.modal.vue';
  import Notificaciones from '@/api-base/notificaciones.service';
  import Colaboradores from '@/api-services/colaboradores.service';
  export default {
    name: 'LayOuts-Notificacion',
    props: {
      value: Object,
    },
    components: {
      NotificacionModal,
    },
    data() {
      return {
        LaNotificacion: null,
        Carga: null,
        Modal: false,
        cargando: false,
        Erroneo: '',
        Comentarios: '',
        MsgError: '',
      }
    },
    watch: {

      Modal(Modal) {
        // return;

        if (!Modal) {
          console.debug(`update:Modal= ${Modal}`);
          this.$nextTick(() => {
            this.$emit('actualizar', this.value);
          });
        }
      }

    },
    computed: {
      CardClass() {
        if (this.Carga.Respuesta != undefined && this.Carga.Respuesta.Aceptada != undefined) {
          if (this.Carga.Respuesta.Aceptada)
            return "bg-success";
          else
            return "bg-danger";
        }
        return "";
      },
      EsperaRespuesta() {
        return Notificaciones.EsperaRespuesta(this.LaNotificacion.tipoNotificacion);
      },
      TituloDias() {
        if (this.Carga && this.Carga.Dias) {
          if (this.Carga.Dias.length > 1)
            return "Para los siguientes días:";
          return "Para el siguiente día:";
        }
        return "";
      },
      Icono() {
        return Notificaciones.Icono(this.LaNotificacion.tipoNotificacion);

        //fa fa-times
      },
      Etiqueta() {
        return Notificaciones.Etiqueta(this.LaNotificacion.tipoNotificacion);
      },
      Datos() {
        return Notificaciones.Datos(this.Carga);

      },
      Titulo() {
        return Notificaciones.Titulo(this.LaNotificacion.tipoNotificacion);
      }
    },
    mounted() {
      this.LaNotificacion = Object.assign({}, this.value);
      this.Carga = JSON.parse(this.value.carga);
    },
    filters: {
      Foto(Registro) {
        return Colaboradores.FotoURL(Registro);
      },

    },
    methods: {
      OnMostrar() {
        if (Notificaciones.Redirigir(this, this.LaNotificacion)) {
          //this.$router.push(`/empleado/acceso-nuevo?n=${this.LaNotificacion.id}`);
          return;
        }
        this.Modal = true;
      },
      FDia(Dia) {
        return moment(Dia).format("dddd D/MMMM/YY");

      },

      OnAutorizar(Parametros) {
        if (Parametros == undefined)
          Parametros = { Comentarios: '', Autorizar: false };
        Notificaciones.put(this, this.LaNotificacion.id, {
          respuesta: Parametros.Comentarios,
          aceptada: Parametros.Autorizar
        }, (response) => {

          /*  if (response.data.borrado) {
              for (var i = 0; i < Notificaciones.Notificaciones.length; i++) {
                console.debug(Notificaciones.Notificaciones[i].id);
                if (Notificaciones.Notificaciones[i].id == response.data.id) {

                  Notificaciones.Notificaciones.borrado = true;
                  i--;
                }
              }
              }*/
          console.debug("Cerrando");
          this.Modal = false;
        });
      }
    }
  }
</script>

<style scoped>
</style>
