const isProd = process.env.NODE_ENV === "production";

export default {
  IsProd() {
    // return true;
    return isProd;
  },
  get Version() {
    return "v0.4.0318";
  },
  get URL() {

    var lURL = localStorage.ApiURL;
    if (lURL)
      return lURL;

   // return "https://api.InvioLabel.eclock.com.mx/api";
    lURL = ((this.IsProd()) ? "https://apiInvioLabel.eclock.com.mx/api" : "http://localhost:7199/api");// "https://debug.apiv6.eclock.com.mx/api")
    //  localStorage.URL = lURL;

    return lURL;
  },
  get SignalRURL() {

    var lURL = localStorage.SignalRURL;
    if (lURL)
      return lURL;
    lURL = "https://ServicesV6.eclock.com.mx/SignalRHub";//((this.IsProd()) ? "https://apiv6.eclock.com.mx/api" : "https://debug.apiv6.eclock.com.mx/api")
    // localStorage.SignalRURL = lURL;

    return lURL;
  },
  GuardaValor(Nombre, Valor) {
    localStorage[Nombre] = Valor;
  },
  ObtenValorBool(Nombre, Predeterminado) {
    var Valor = localStorage[Nombre];
    if (Predeterminado == null || Predeterminado == undefined)
      Predeterminado = false;
    if (Valor == null || Valor == undefined)
      return Predeterminado;
    if (Valor == true || Valor == "true") 
      return true;
    return false;
  },
  CabiarTema(Obscuro) {
    if (Obscuro == null || Obscuro == undefined) {
      if (localStorage.Obscuro == null || localStorage.Obscuro == undefined) {
        localStorage.Obscuro = true;
        Obscuro = true;
      }
      else
        Obscuro = localStorage.Obscuro ; 
    }
    else
      localStorage.Obscuro = Obscuro
    let docClasses = document.body.classList;
    console.debug(`Obscuro=${Obscuro}`);
    if (Obscuro == true || Obscuro=="true") {
      docClasses.remove('white-content');
      docClasses.add('maz-is-dark');

    } else {
      docClasses.add('white-content');
      docClasses.remove('maz-is-dark');
    }
  },
}
