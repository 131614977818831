
import Base from '@/api-base/base'
const BaseURL = `Colaboradores`;

export default {
  GetsResponse: null,
  Listado: [],
  getsSelect(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    if (this.GetsResponse != null)
      OnCorrecto(this.GetsResponse);
    return Base.getsBaseSuscripcion(Vista, BaseURL, { PaginaLen: 1000 }, (response) => {
      this.GetsResponse = response;
      this.Listado = response.data.listado;
      if (OnCorrecto != undefined)
        OnCorrecto(response);

    }, OnErroneo, OnFinalizado);
  },
  TotalesPorGets(Vista, Agrupacion, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/TotalesPor/${Agrupacion}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  putCamposLaborales(Vista, CamposLaborales, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/CamposLaborales`);
    return Base.put(Vista, URL, CamposLaborales, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  putCamposPersonales(Vista, CamposPersonales, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/CamposPersonales`);
    return Base.put(Vista, URL, CamposPersonales, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  //Suscripciones
  CumpleanosGets(Vista, UbicacionID, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/Cumpleanos/${UbicacionID}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  //quita lo borrado
  patch(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {

    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.patch(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
    //return Base.patchBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  FiltroGets(Vista, Params, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/Filtro`, Params, OnCorrecto, OnErroneo, OnFinalizado);
  },
  gets(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, BaseURL, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  GetsEnriquecido(Vista, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/Enriquecido`, BaseGetsListado, OnCorrecto, OnErroneo, OnFinalizado);
  },
  FotoURL(Colaborador) {
    var R = '/img/default-avatar.png';
    // console.debug(Colaborador);
    if (Colaborador != undefined && Colaborador != null && Colaborador.urlMiniatura != undefined && Colaborador.urlMiniatura != null && Colaborador.urlMiniatura != "") {
      R = Colaborador.urlMiniatura;
      //Parche para las importaciones de eClock
      R = R.replace("http://", "https://");
    }
    if (Colaborador != undefined && Colaborador != null && Colaborador.UrlMiniatura != undefined && Colaborador.UrlMiniatura != null && Colaborador.UrlMiniatura != "") {
      R = Colaborador.UrlMiniatura;
      //Parche para las importaciones de eClock
      R = R.replace("http://", "https://");
    }
    //console.debug(R);
    return R;
  },
  //Obtiene la plantilla de importaicón para la suscripción activa
  ExcelPlantillaGet(Vista, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel/Plantilla`);
    return Base.get(Vista, URL, { responseType: 'blob', params: { EsXls: false } }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  //Importa un archivo de excel
  ExcelPlantillaPatch(Vista, Archivo, OnUploadProgress, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('ArchivoExcel', Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Excel`);
    return Base.patch(Vista, URL, formData, {

      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: OnUploadProgress
    }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  //Importa desde interfaz
  InterfazPatch(Vista,  OnCorrecto, OnErroneo, OnFinalizado) {

    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Interfaz`);
    return Base.patch(Vista, URL, {}, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  //Envia una foto para su identificacion
  IdentificarPatch(Vista, Archivo, OnUploadProgress, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('Foto', Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/Identificar`);
    return Base.patch(Vista, URL, formData, {

      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: OnUploadProgress
    }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  //Agrega o actualiza la foto de un colaborador
  FotoPatch(Vista, ID, Orden, Archivo, OnUploadProgress, OnCorrecto, OnErroneo, OnFinalizado) {
    let formData = new FormData();
    formData.append('Foto', Archivo);
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}/Foto?Orden=${Orden}`);
    return Base.patch(Vista, URL, formData, {

      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: OnUploadProgress
    }, OnCorrecto, OnErroneo, OnFinalizado);
  },
  put(Vista, ID, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = Base.ObtenURLSuscripcion(`${BaseURL}/${ID}`);
    return Base.put(Vista, URL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  post(Vista, Datos, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.postBaseSuscripcion(Vista, BaseURL, Datos, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  get(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${id}`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  delete(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.deleteBaseSuscripcion(Vista, `${BaseURL}/${id}`, OnCorrecto, OnErroneo, OnFinalizado);
  },

  getAceptarInvitacion(Vista, SuscripcionID, ColaboradorID, OnCorrecto, OnErroneo, OnFinalizado) {
    var URL = `${Base.URL}/Suscripciones/${SuscripcionID}/${BaseURL}/${ColaboradorID}/AceptarInvitacion`;
    //  console.debug(URL);
    return Base.get(Vista, URL, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  getHome(Vista, id, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${id}/Home`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
  GetHuellas(Vista, ColaboradorID, OnCorrecto, OnErroneo, OnFinalizado) {
    return Base.getsBaseSuscripcion(Vista, `${BaseURL}/${ColaboradorID}/Huellas`, {}, OnCorrecto, OnErroneo, OnFinalizado);
  },
}
