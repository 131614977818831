const isProd = process.env.NODE_ENV === "production";

export default {
  IsProd() {
    // return true;
    return isProd;
  },
  //Identificaror del producto en caso de InvioLabel es 4
  get productoID() {
    return 4;
  },
  get ElApiKey() {
    return "f4b5ad93-5307-4a1d-8f46-2941405aa7af";
  },
  get URL() {

    return ((this.IsProd()) ? "https://InvioLebel.eclock.com.mx/" : "http://localhost:8091/");
    
  },
  ///Identificador de la suscripción InvioLabel
  get SuscripcionIDInvioLabel() {
    return "Su.FkYdfiHd01.I1";
  },
  //Ruta cuando tenemos que confirmar un usuario
  get URLConfirmacion() {
    return `${this.URL}confirmacion/`;
  },
  //Ruta cuando tenemos la autentificación confirmada
  get URLAutentificacion() {
    return `${this.URL}auth/`;
    //return "http://localhost:8091/auth/";
  },
  get UsarSignalR() {
    return false;
  },
}
