<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a href="https://www.eClock.com.mx/eVa"
             target="_blank"
             rel="noopener"
             class="nav-link">
            BeOriginal
          </a>
        </li>
      </ul>
      <div class="copyright text-wrap" @click="OnClick">
        &copy; {{ year }}, creado con <i class="tim-icons icon-heart-2"></i> para mejorar la seguridad de lo que consumes. {{Version}} {{Datos}}
      </div>
    </div>
  </footer>
</template>
<script>
  import BaseService from '@/api-base/base.service'
  import Base from '@/api-base/base';
  export default {
    data() {
      return {
        Version: Base.Version,
        year: new Date().getFullYear(),
        Datos: ''
      };
    },
    mounted() {
      BaseService.CabiarTema();
    },
    methods: {
      OnClick() {
        if (!BaseService.IsProd())
          this.Datos = `${localStorage.suscripcionID}/${localStorage.sesion}`
      }
    }
  };
</script>
<style></style>
