import HomeLayout from 'src/Vistas/LayOuts/HomeLayout.vue';
import LimpioLayout from 'src/Vistas/LayOuts/LimpioLayout.vue';

import VacioLayout from 'src/Vistas/LayOuts/VacioLayout.vue';

import VistasAuthLayout from 'src/Vistas/LayOuts/AuthLayout.vue';
// GeneralViews
import PaginaNoEncontrada from 'src/Vistas-base/Main/PaginaNoEncontrada.vue';


let authVistas = {
  path: '/',
  component: VistasAuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Inicio de sesión',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Usuarios/LogIn.vue')
    },
    {
      path: '/confirmacion/:confirmacionLinkID',
      name: 'Confirmacion',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Usuarios/LogIn.vue')
    },
    {
      path: '/auth/:authLinkID',
      name: 'Autentificacion',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Usuarios/LogIn.vue')
    },
    {
      path: '/registro',
      name: 'Registrarse',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Usuarios/Registro.vue')
    },
    {
      path: '/recuperar',
      name: 'Recuperar Contraseña',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Usuarios/RecuperarPassword.vue')
    },
    {
      path: '/r/:linkID',
      name: 'Referencia',
      component: () =>
        import(/* webpackChunkName: "referencia" */ 'src/Vistas-base/Referencias/Consulta.vue')
    },
    {
      path: '/usuarios/nuevasuscripcion',
      name: 'Nueva Suscripción',
      component: () =>
        import(/* webpackChunkName: "suscripcion" */ 'src/Vistas-base/Usuarios/NuevaSuscripcion.vue')
    },
    {
      path: '/dispositivos/qr-nuevo-checador',
      name: 'Nuevo Dispositivo',
      component: () =>
        import(/* webpackChunkName: "qr-nuevo-checador" */ 'src/Vistas/Dispositivos/QR.NuevoChecador.vue')
    },
    {
      path: '/reuniones/:id',
      name: 'Invitación a reunión',
      component: () => import(/* webpackChunkName: "usuarios" */ 'src/Vistas/Reuniones/Invitacion.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: 'l/:codigo',
      name: 'Home Lectura',
      component: () =>
        import(/* webpackChunkName: "main" */ 'src/Vistas/Main/HomeInvitado.vue'),
      meta: { requiresAuth: false }
    },
  ]
};
//
let vacioPages = {
  path: '/main',
  component: VacioLayout,
  name: 'VacioLayOut',
  children: [
    {
      path: '/main/home',
      name: 'Home',
      component: () =>
        import(/* webpackChunkName: "main" */ 'src/Vistas/Main/Home.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: 'invitado',
      name: 'Home Inviado',
      component: () =>
        import(/* webpackChunkName: "main" */ 'src/Vistas/Main/HomeInvitado.vue'),
      meta: { requiresAuth: false }
    },

  ]
}


let mainPages = {
  path: '/main',
  component: LimpioLayout,
  name: 'MainHome',
  children: [

    {
      path: 'usuario',
      name: 'Home Usuario',
      component: () =>
        import(/* webpackChunkName: "main" */ 'src/Vistas/Main/HomeUsuario.vue'),
      meta: { requiresAuth: true }
    },

    {
      path: 'homeVigilante',
      name: 'Vigilante',
      component: () =>
        import(/* webpackChunkName: "main" */ 'src/Vistas/Main/HomeVigilante.vue'),
      meta: { requiresAuth: true }
    },
  ]
};

let suscripcionesSinMenuPages = {

  children: [
    {
      path: 'pregunta',
      name: 'Sin Suscripción',
      component: () =>
        import(/* webpackChunkName: "suscripciones" */ 'src/Vistas-base/Suscripciones/Pregunta.vue'),
      meta: { requiresAuth: true }
    },

  ]
};


let suscripcionesPages = {
  path: '/suscripciones',
  component: HomeLayout,
  name: 'Suscripciones P',
  children: [
    {
      path: '/suscripciones',
      name: 'Suscripciones',
      component: () =>
        import(/* webpackChunkName: "suscripciones" */ 'src/Vistas/Suscripciones/Listado.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/suscripciones/:id',
      name: 'Suscripcion',
      component: () =>
        import(/* webpackChunkName: "ubicaciones" */ 'src/Vistas/Suscripciones/Edicion.vue'),
      meta: { requiresAuth: true }
    },
  ]
};

let productosPages = {
  path: '/productos',
  component: HomeLayout,
  name: 'Productos P',
  children: [
    {
      path: '/productos',
      name: 'Productos',
      component: () =>
        import(/* webpackChunkName: "suscripciones" */ 'src/Vistas/Productos/Listado.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/productos/:id',
      name: 'Producto',
      component: () =>
        import(/* webpackChunkName: "ubicaciones" */ 'src/Vistas/Productos/Edicion.vue'),
      meta: { requiresAuth: true }
    },
  ]
};

let lotesPages = {
  path: '/lotes',
  component: HomeLayout,
  name: 'Lotes P',
  children: [
    {
      path: '/lotes',
      name: 'Lotes',
      component: () =>
        import(/* webpackChunkName: "suscripciones" */ 'src/Vistas/Lotes/Listado.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/lotes/:id',
      name: 'Lote',
      component: () =>
        import(/* webpackChunkName: "ubicaciones" */ 'src/Vistas/Lotes/Edicion.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/labels/registro',
      name: 'Registro',
      component: () =>
        import(/* webpackChunkName: "ubicaciones" */ 'src/Vistas/Labels/Registro.vue'),
      meta: { requiresAuth: true }
    },

    {
      path: '/labels/mislabels',
      name: 'Mis Labels',
      component: () =>
        import(/* webpackChunkName: "ubicaciones" */ 'src/Vistas/Labels/MisLabels.vue'),
      meta: { requiresAuth: true }
    },
  ]
};

let personalPages = {
  path: '/personal',
  component: HomeLayout,
  name: 'PersonalHome',
  children: [


  ]
};

let accesosPages = {
  path: '/Accesos',
  component: HomeLayout,
  name: 'AccesosHome',
  children: [
    {
      path: '/accesos',
      name: 'Accesos',
      component: () =>
        import(/* webpackChunkName: "accesos" */ 'src/Vistas/Accesos/Listado.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/accesos/nuevo',
      name: 'Nueva Checada',
      component: () =>
        import(/* webpackChunkName: "accesos" */ 'src/Vistas/Accesos/NuevoGeneral.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/accesos/mapa',
      name: 'Mapa',
      component: () =>
        import(/* webpackChunkName: "accesos" */ 'src/Vistas/Accesos/Mapa.vue'),
      meta: {
        hideContent: true,
        hideFooter: true,
        requiresAuth: true
      }
    },
  ]
}


let ubicacionesPages = {
  path: '/',
  component: HomeLayout,
  name: 'UbicacionesHome',
  children: [
    {
      path: '/ubicaciones',
      name: 'Ubicaciones',
      component: () =>
        import(/* webpackChunkName: "ubicaciones" */ 'src/Vistas/Ubicaciones/Listado.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ubicaciones/:id',
      name: 'Ubicacion',
      component: () =>
        import(/* webpackChunkName: "ubicaciones" */ 'src/Vistas/Ubicaciones/Edicion.vue'),
      meta: { requiresAuth: true }
    }

  ]
};

let dispositivosPages = {
  path: '/',
  component: HomeLayout,
  name: 'DispositivosHome',
  children: [
    {
      path: '/dispositivos',
      name: 'Dispositivos',
      props: true,
      component: () =>
        import(/* webpackChunkName: "dispositivos" */ 'src/Vistas/Dispositivos/Listado.vue'),
      meta: { requiresAuth: true }
    },

  ]
};

let embajadoresPages = {
  path: '/Embajadores',
  component: HomeLayout,
  name: 'EmbajadoresHome',
  children: [
    {
      path: '/embajadores',
      name: 'Embajadores',
      component: () =>
        import(/* webpackChunkName: "embajadores" */ 'src/Vistas-base/Referencias/Listado.vue'),
      meta: { requiresAuth: true }
    },

  ]
}

let impresionVistas = {
  path: '/impresion',
  component: VistasAuthLayout,
  name: 'Impresion',
  children: [
    {
      path: '/embajadores/suscripcion/:linkID',
      name: 'Embajadores - Suscripcion',
      component: () =>
        import(/* webpackChunkName: "embajadores" */ 'src/Vistas-base/Referencias/Suscripcion.impresion.vue'),
      meta: { requiresAuth: true }
    },
  ]
};


let usuariosPages = {
  path: '/',
  component: LimpioLayout,
  name: 'Usuarios',
  children: [
    {
      path: '/usuarias/perfil',
      name: 'Perfil',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas/Usuarias/PerfilPropio.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/usuarios/perfil',
      name: 'Mi Perfil',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Usuarios/PerfilPropio.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/usuarios/tomafoto',
      name: 'Toma de Foto',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Usuarios/TomaFoto.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/usuarios',
      name: 'Usuarios',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Usuarios/Listado.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/usuarias',
      name: 'usuarios',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas/Usuarias/Listado.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/usuarios/nuevo',
      name: 'Nuevo Usuario',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Usuarios/Nuevo.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/usuarios/comentarios',
      name: 'Tu Opinion',
      component: () =>
        import(/* webpackChunkName: "usuarios" */ 'src/Vistas-base/Main/Comentarios.vue'),
      meta: { requiresAuth: true }
    },

    {
      path: '/reuniones/nueva',
      name: 'Nueva reunion',
      component: () => import(/* webpackChunkName: "usuarios" */ 'src/Vistas/Reuniones/Nueva.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/reuniones',
      name: 'Reuniones',
      component: () => import(/* webpackChunkName: "usuarios" */ 'src/Vistas/Reuniones/Listado.vue'),
      meta: { requiresAuth: true }
    }, {
      path: '/reuniones/edicion',
      name: 'Editar reunion',
      component: () => import(/* webpackChunkName: "usuarios" */ 'src/Vistas/Reuniones/Edicion.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/scan',
      name: 'Escanear QR',
      component: () => import(/* webpackChunkName: "qr-nuevo-checador" */ 'src/Vistas/Scan.vue'),
      meta: {
        requiresAuth: true,
        hideContent: true,
        hideFooter: true
      }
    },

  ]
};

const routes = [
  {
    path: '/',
    name: 'Default',
    component: () =>
      import(/* webpackChunkName: "default" */ 'src/Vistas-base/Main/Default.vue'),
  },
  authVistas,
  vacioPages,
  mainPages,
  //suscripcionesSinMenuPages,
  suscripcionesPages,
  productosPages,
  lotesPages,
  usuariosPages,
  dispositivosPages,
  personalPages,

  accesosPages,

  ubicacionesPages,
  embajadoresPages,

  impresionVistas,
  // Agregar código para mostrar vacio
  { path: '*', name: 'Página no encontrada', component: PaginaNoEncontrada }
];

export default routes;
