<template>
  <base-nav v-model="showMenu"
            class="navbar-absolute top-navbar"
            type="white"  containerClasses="container-fluid px-0"
            :transparent="true">
    <div slot="brand" class="navbar-wrapper w-100 d-flex justify-content-between">

        <div class="navbar-minimize d-inline"></div>
        <div class="navbar-toggle d-inline"
             :class="{ toggled: $sidebar.showSidebar }">

          <button v-if="routeName!='Home'" class="navbar-toggler  " @click="$router.go(-1)">
            <p><i class="tim-icons text-white  icon-minimal-left "></i></p>
            
          </button>
        </div>
        <div class="navbar-brand text-truncate text-white">{{ routeName }}</div>
        <base-dropdown title-tag="a"
                       class="nav-item ml-auto"
                       title-classes="nav-link" menuOnRight
                       menu-classes="dropdown-black ">
          <template slot="title">
            <div class="photo"><img :src="FotoURL" /></div>
            <b class="caret "></b>

          </template>
          
          <li class="nav-link ">
            <!--      <a href="#" class="nav-item dropdown-item">
      <i class="tim-icons icon-single-02"></i>
      Mi perfil

    </a>

        to="/usuarios/perfil"
        -->
            <router-link to="/usuarias/perfil" class="nav-item dropdown-item text-white">
              <i class="tim-icons icon-single-02"></i>
              Mi perfil
            </router-link>

          </li>

          <li class="nav-link">
            <a href="https://support.eclock.com.mx/portal/es/kb/eclock" target="_blank" class="nav-item dropdown-item text-white">
              <i class="tim-icons icon-support-17"></i>
              Soporte
            </a>
          </li>
          <div class="dropdown-divider"></div>
          <li class="nav-link" v-for="suscripcionUsuario in SU" v-bind:key="suscripcionUsuario.suscripcionID">
            <a href="#" class="nav-item dropdown-item text-white" @click="CambiaSuscripcion(suscripcionUsuario.suscripcionID)">
              {{suscripcionUsuario.suscripcion.etiqueta}}
            </a>
          </li>
          <div class="dropdown-divider"></div>
          <li class="nav-link">
            <router-link to="/login" class="nav-item dropdown-item text-white">
              <i class="tim-icons icon-user-run"></i>
              Cerrar sesión
            </router-link>
            <!-- <a href="#" class="nav-item dropdown-item">
      <i class="tim-icons icon-user-run"></i>
      Cerrar sesión
    </a>-->
          </li>

        </base-dropdown>
     
    </div>
    <div slot="toggle-button">

    </div>
  
     
    
  </base-nav>
</template>
<script>
  import { BaseNav, Modal } from '@/components';
  import SidebarToggleButton from './SidebarToggleButton';
  import swal from 'sweetalert2';

  import Constantes from '@/api-services/Constantes';
  import Usuarios from '@/api-base/usuarios.service';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';

  export default {
    components: {
      SidebarToggleButton,
      BaseNav,
      Modal
    },
    computed: {
      SU() {
        if (this.SuscripcionesUsuario == null)
          return [];
        return this.SuscripcionesUsuario.filter(x => x.suscripcion.productoID == Constantes.productoID);
      },
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      isRTL() {
        return false;
      },
      HayAtras() {
        return         window.history.length;
      }
    },
    props: {

      FotoURL: {
        type: String,
        default: '/img/default-avatar.png',
        description: 'Contiene la url de la foto del usuario'
      },
      Nombre: {
        type: String,
        default: 'El Usuario',
        description: 'Contiene el nombre del usuario'
      },
      SuscripcionesUsuario: Array,
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: ''
      };
    },
    mounted() {
      console.debug(this.$root.historyCount);
    },
    methods: {

      OnBuscar() {
        this.searchModalVisible = false;
        this.$router.push({ path: '/personal', query: { b: this.searchQuery } });
        this.searchQuery = "";
        this.showMenu = false;
      },
      CambiaSuscripcion(SuscripcionID) {
        console.debug(SuscripcionID);
        SuscripcionesUsuarios.setSuscripcionID(SuscripcionID);
        Usuarios.getPerfilLocal((UsuarioPerfil) => { }, true);
        swal.fire({
          title: 'Listo',
          text: 'Se ha cambiado la suscripción seleccionada.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-fill'
          },
          buttonsStyling: false
        }).then(() => {
          // this.$router.push({ path: '/' });
          window.location.reload(false);
        });

      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      }
    }
  };
</script>
<style scoped>
  .top-navbar {
    top: 0px;
  }
</style>
